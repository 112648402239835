<template>
  <validation-observer
    ref="examinationSchedulerSubjectSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="examinationSchedulerSubjectSaveModal"
      body-class="position-static"
      centered
      no-close-on-backdrop
      size="lg"
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="listRoom">
              <template v-slot:label>
                Phòng học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phòng học"
                rules="required"
              >
                <v-select
                  v-model="targetData.listRoom"
                  :options="roomsSelection"
                  :reduce="option => option.name"
                  label="name"
                  :close-on-select="false"
                  multiple
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label-for="status">
              <template v-slot:label>
                Số phòng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số phòng"
                rules="required"
              >
                <b-form-input
                  id="totalRoom"
                  v-model="targetData.totalRoom"
                  type="number"
                  name="totalRoom"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="examType">
              <template v-slot:label>
                Hình thức thi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Hình thức thi"
                rules="required"
              >
                <v-select
                  v-model="targetData.examType"
                  :options="examTypeOptions"
                  :reduce="option => option.value"
                  label="label"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="daySession">
              <template v-slot:label>
                Thời gian <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thời gian"
                rules="required"
              >
                <b-form-radio-group
                  id="daySession"
                  v-model="targetData.daySession"
                  name="daySession"
                  :options="daySessionOptions"
                  :state="getElementState(errors)"
                  inline
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="startDate">
              <template v-slot:label>
                Ngày bắt đầu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày bắt đầu"
                rules="required"
              >
                <b-form-datepicker
                  id="datepicker-dateformat1"
                  v-model="targetData.startDate"
                  :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                  locale="vi"
                  format="dd/mm/yyyy"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('examinationSchedulerSubjectSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormDatepicker, BFormRadioGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { DAY_SESSIONS } from '@/const/status'
import { EXAM_TYPE } from '@/const/type'

export const Attr = Object.freeze({
  store: 'examinationSchedulerSubject',
})

export default {
  name: 'ExaminationSchedulerSubjectSave',
  components: {
    BFormInput,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BCol,
    BRow,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: 0,
        totalRoom: 0,
        listRoom: [],
        daySession: 1,
        examType: null,
        status: 0,
        startDate: '',
      },
      daySessionOptions: DAY_SESSIONS,
      examTypeOptions: EXAM_TYPE,
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      roomsSelection: 'rooms/roomSelection',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  watch: {
    'targetData.listRoom': {
      handler(newValue) {
        const originalListRoom = this.mainData.listRoom ? this.mainData.listRoom.split(', ') : []
        if (JSON.stringify(newValue) !== JSON.stringify(originalListRoom)) {
          this.targetData.totalRoom = newValue.length
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
      getRoomsSelection: 'rooms/getRoomsSelection',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },

    async onShow() {
      this.isLoading = true
      try {
        await this.getRoomsSelection()
        if (this.mainData) {
          this.targetData.id = this.mainData.id
          this.targetData.startDate = this.mainData.startDate
          this.targetData.status = this.mainData.status
          if (this.mainData.listRoom) {
            this.targetData.listRoom = this.mainData.listRoom.split(', ')
          }
          this.targetData.totalRoom = this.mainData.totalRoom || 0
          this.targetData.daySession = this.mainData.daySession || 1
          this.targetData.examType = this.mainData.examType
        }
        if (this.isCreated) {
          this.targetData.status = 0
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .examinationSchedulerSubjectSaveFormRef
        .reset()
      this.targetData = {
        id: 0,
        totalRoom: 0,
        listRoom: [],
        daySession: 1,
        examType: null,
        status: 0,
        startDate: '',
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .examinationSchedulerSubjectSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        const listRoomString = this.targetData.listRoom.join(', ')
        const payload = {
          ...this.targetData,
          listRoom: listRoomString,
        }
        try {
          const response = this.isCreated
            ? await this.createData(payload)
            : await this.updateData(payload)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('examinationSchedulerSubjectSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
