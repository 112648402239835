<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="6"
                  md="4"
                >
                  <b-form-group
                    label-for="trainingSystemId"
                  >
                    <v-select
                      v-model="filter.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      placeholder="Hệ đào tạo"
                      @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="4"
                >
                  <b-form-group
                    label-for="courseId"
                  >
                    <v-select
                      v-model="filter.courseId"
                      :options="courses"
                      :reduce="option => option.value"
                      placeholder="Khóa học"
                      @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="courseSemesterId"
                  >
                    <v-select
                      v-model="filter.courseSemesterId"
                      :options="courseSemesters"
                      :reduce="option => option.value"
                      placeholder="Học kỳ"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="ml-1"
                    @click="onSearchMainData"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="FilterIcon" /> Lọc
                    </span>
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.examinationSchedulerSaveModal
                    variant="success"
                    size="sm"
                    class="ml-1"
                    @click="onCreateMainData"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm mới
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="vgt-table striped bordered"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  row-style-class="vgt-row"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'startDate'">
                      {{ getVnDate(props.row.startDate) }}
                    </span>
                    <span v-else-if="props.column.field === 'endDate'">
                      {{ getVnDate(props.row.endDate) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          variant="success"
                          class="btn-icon"
                          size="sm"
                          title="Chốt lịch thi"
                          @click="onConfirmData(props.row)"
                        >
                          <feather-icon icon="CheckIcon" />
                        </b-button>
                        <b-button
                          v-if="updatable"
                          v-b-modal.examinationSchedulerSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-button
                          v-if="examinationSchedulerSubjectReadable"
                          variant="info"
                          class="btn-icon"
                          size="sm"
                          title="Chi tiết lịch thi học phần"
                          @click="onOpenSubjectList(props.row)"
                        >
                          <feather-icon icon="ListIcon" />
                        </b-button>
                        <b-button
                          v-if="deletable"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <ExaminationSchedulerSave
      ref="examinationSchedulerSaveFormRef"
      :main-data="currentMainData"
      @succeed="onSucceed"
    />
    <b-modal
      id="modal-list-subject"
      ref="modal-list-subject"
      ok-title="Đóng"
      cancel-title="Đóng"
      centered
      size="xl"
      hide-header
      ok-only
      no-close-on-backdrop
      @ok="onSucceed"
    >
      <ExaminationSchedulerSubject :data-send="currentMainData" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BCard, BCol, BContainer, BForm, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ExaminationSchedulerSave from '@/views/examination-scheduler/ExaminationSchedulerSave.vue'
import ExaminationSchedulerSubject from '@/views/examination-scheduler/subjects/ExaminationSchedulerSubject.vue'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export const Attr = Object.freeze({
  store: 'examinationScheduler',
})

export default {
  name: 'ExaminationScheduler',
  directives: {
    Ripple,
  },
  components: {
    ExaminationSchedulerSave,
    ExaminationSchedulerSubject,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BForm,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        sort: '',
        status: '',
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
    }),
    // statusFilterDropdownItems() {
    //   return this.statuses.map(item => ({ value: item.value, text: item.label }))
    // },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `${this.resourceName.shortName}`,
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Hệ ĐT',
          field: 'trainingSystemName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Khóa học',
          field: 'courseName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Học kỳ',
          field: 'semesterName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày bắt dầu',
          field: 'startDate',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ngày kết thúc',
          field: 'endDate',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '80px',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.EXAMINATION_SCHEDULER)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.EXAMINATION_SCHEDULER)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.EXAMINATION_SCHEDULER)
    },
    examinationSchedulerSubjectReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.EXAMINATION_SCHEDULER_SUBJECT)
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.filter.organizationId = (this.user.orgId ? this.user.orgId : 0)
      await Promise.all([
        this.getTrainingSystems({ organizationId: this.filter.organizationId }),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      updateData: `${Attr.store}/updateData`,
      deleteMainData: `${Attr.store}/deleteData`,
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      await this.getCourses(this.filter)
    },

    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      await Promise.all([
        this.getCourseSemesters({ organizationId: getUser().orgId, courseId: this.filter.courseId, status: '' }),
      ])
    },

    onCreateMainData() {
      this.currentMainData = undefined
    },

    onConfirmData(data) {
      this.$swal({
        title: 'Bạn chắc chắn muốn chốt lịch thi ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const {
              rowNum,
              originalIndex,
              vgt_id,
              ...rest
            } = data
            const updatedData = {
              ...rest,
              status: 1,
            }
            const response = await this.updateData(updatedData)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Chốt lịch thi thành công',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainDataFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },

    onOpenSubjectList(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$refs['modal-list-subject'].show()
    },

    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName} <span class="text-danger">${mainData.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },

    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },

    getVnDate(rawDate) {
      const tmpDate = rawDate.split('-')
      return `${tmpDate[2]}-${tmpDate[1]}-${tmpDate[0]}`
    },

    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async onSearchMainData() {
      await this.getMainDataFromStore()
    },
  },
}
</script>
